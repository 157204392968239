.number {
  width: 68px;
  height: 88px;
  position: relative;
  overflow: hidden;
  border: 4px solid var(--number-box-color);
  box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  z-index: 100;
}

.number__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  background: var(--number-box-color);
  box-shadow: inset 0px 0px 28px rgba(9, 7, 13, 0.8);
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
}

.number__container_rolling {
  -webkit-animation: start 0.5s ease-in forwards,
    goaround 0.1s linear 0.5s infinite;
  animation: start 0.5s ease-in forwards, goaround 0.1s linear 0.5s infinite;
}

.number__container_stoping {
  -webkit-animation: stop 0.5s ease-out forwards;
  animation: stop 0.5s ease-out forwards;
}

.number__text {
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 78%;
  letter-spacing: 0.01em;
  color: var(--text-color);
  padding: 6px 0;
}

.number__text_type_win {
  animation: result_win 1s linear forwards;
}

.number__text_type_lost {
  animation: result_lost 1s linear forwards;
}

.number__border-ledge {
  width: 36px;
  height: 12px;
  position: absolute;
  left: calc(50% - 36px / 2);
  top: -6px;
  background: var(--number-box-color);
  border-radius: 50px;
  box-shadow: 0px 4px 8px rgba(9, 7, 13, 0.5);
  z-index: 5;
}

.number__border-ledge_type_down {
  top: unset;
  bottom: -6px;
  box-shadow: 0px -4px 8px rgba(9, 7, 13, 0.5);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

@keyframes result_win {
  0% {
    color: var(--text-color);
  }

  30% {
    color: var(--win-color);
  }

  70% {
    color: var(--win-color);
  }

  100% {
    color: var(--text-color);
  }
}

@keyframes result_lost {
  0% {
    color: var(--text-color);
  }

  30% {
    color: var(--lose-color);
  }

  70% {
    color: var(--lose-color);
  }

  100% {
    color: var(--text-color);
  }
}

@-webkit-keyframes start {
  0% {
    -webkit-transform: translateY(-140px);
    transform: translateY(-140px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes start {
  0% {
    -webkit-transform: translateY(-140px);
    transform: translateY(-140px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes goaround {
  0% {
    -webkit-transform: translateY(-325px);
    transform: translateY(-325px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes goaround {
  0% {
    -webkit-transform: translateY(-325px);
    transform: translateY(-325px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes stop {
  0% {
    -webkit-transform: translateY(-300px);
    transform: translateY(-300px);
  }

  50% {
    -webkit-transform: translateY(-135px);
    transform: translateY(-135px);
  }

  75% {
    -webkit-transform: translateY(-145px);
    transform: translateY(-145px);
  }

  100% {
    -webkit-transform: translateY(-140px);
    transform: translateY(-140px);
  }
}

@keyframes stop {
  0% {
    -webkit-transform: translateY(-300px);
    transform: translateY(-300px);
  }

  50% {
    -webkit-transform: translateY(-135px);
    transform: translateY(-135px);
  }

  75% {
    -webkit-transform: translateY(-145px);
    transform: translateY(-145px);
  }

  100% {
    -webkit-transform: translateY(-140px);
    transform: translateY(-140px);
  }
}
