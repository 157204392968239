.dice-result {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.dice-result__number-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.dice-result__comma {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 78%;
  color: var(--number-box-color);
  margin: 0;
  padding: 0 2px;
}
