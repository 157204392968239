@import url(./assets/fonts/Rubik/stylesheet.css);

html, body {
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
  touch-action: pan-x pan-y;
}

body {
  margin: 0;
  font-family: 'Rubik', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, blockquote {
  padding: 0;
  margin: 0;
  color: var(--text-color);
  white-space: pre-line;
}

a {
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
}

button {
  font-family: 'Rubik', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: var(--text-color);
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input, textarea {
  color: var(--text-color);
  background-color: transparent;
  border: none;
  outline: transparent;
  padding: 0;
}

:root {
  --text-color: #FFFFFF;
  --dark-text-color: #170936;
  --light-text-color: #D5BEFF;
  --contrast-color: #6A33BF;
  --contrast-color-opacity: rgba(106, 51, 191, 0.16);
  --text-on-contrast-color: #FFFFFF;
  --bg-color: #241840;
  --lose-color: #e73b45;
  --win-color: #40cf71;

  --border-color: #393a40;
  --container-bg-color: #291E45;
  --container-secondary-bg-color: #2F234B;

  --number-box-color: #4d4065;

  --toastify-color-dark: var(--container-bg-color);
  --toastify-font-family: "Rubik";
  --toastify-toast-width: 234px;
  --toastify-toast-min-height: unset;
  --toastify-color-progress-dark: transparent;
}