.options {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.options_type_wheel-game {
  height: fit-content;
  margin: auto 0;
}

.options_disabled {
  pointer-events: none;
}

.options__payout-block {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: var(--container-secondary-bg-color);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 8px 12px;
  transition: opacity 0.3s ease-in;
}

.options_disabled .options__payout-block {
  opacity: 0.3;
}

.options__payout-text-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--border-color);
  box-sizing: border-box;
}

.options__payout-text-block:last-of-type {
  border: none;
  align-items: flex-end;
}

.options__block-title {
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: var(--text-color);
  opacity: 0.8;
}

.options__payout-numbers {
  width: calc(100% - 16px);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  padding-top: 4px;
}

.options__bet-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  background: var(--container-secondary-bg-color);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 8px 12px;
  transition: opacity 0.3s ease-in;
}

.options_disabled .options__bet-block {
  opacity: 0.3;
}

.options__set-bet-block {
  width: 100%;
  display: grid;
  grid-template-columns: 36px 1fr 36px;
  gap: 4px;
}

.options__bet-btn {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  text-align: center;
  color: var(--text-color);
  background: var(--container-bg-color);
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  padding: 8px;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
}

.options__bet-btn_disabled {
  opacity: 0.7;
  pointer-events: none;
}

.options__bet-box {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: var(--container-bg-color);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px;
}

.options__currency-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.options__bet-input {
  width: 100%;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  color: var(--text-color);
  background: transparent;
  border: none;
  padding: 0;
  margin-left: 8px;
  outline: transparent;
}

.options__bet-options {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr) 2fr;
  gap: 4px;
}

.options__autoplay-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--container-secondary-bg-color);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 8px 12px;
  position: relative;
  transition: opacity 0.3s ease-in;
  z-index: 1;
}

.options_disabled .options__autoplay-block {
  opacity: 0.3;
}

.options__autoplay-switch-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options__autoplay-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options__input_invisible {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.options__autoplay-btn {
  width: 42px;
  height: 26px;
  position: relative;
  overflow: hidden;
  background-color: var(--container-bg-color);
  border-radius: 16px;
  border: none;
  padding: 0;
  cursor: pointer;
  z-index: 10;
}

.options__btn-background {
  width: 42px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--contrast-color);
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.options__autoplay-btn_active .options__btn-background {
  opacity: 1;
}

.options__switch {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  background: var(--text-color);
  border-radius: 50%;
  transition: left 0.3s ease;
}

.options__switch_active {
  left: 18px;
}

.options__autoplay-input {
  width: 39px;
  height: 24px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 133%;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--text-color);
  background: var(--container-bg-color);
  border-radius: 8px;
  border: none;
  padding: 4px;
  outline: transparent;
  transition: color 0.3s ease-in;
}

.options__autoplay-input_disabled {
  color: rgba(255, 255, 255, 0.15);
}

.options__slider-box {
  width: 100%;
  position: relative;
  margin-top: 8px;
  touch-action: pan-y;
}

.options__autoplay-slider {
  touch-action: pan-y;
  position: relative;
  z-index: 10;
  opacity: 1 !important;
  transition: opacity 0.3s ease-in !important;
}

.options__autoplay-slider_disabled {
  opacity: 0 !important;
}

.options__autoplay-slider.MuiSlider-root {
  width: 100% !important;
  height: 20px !important;
  color: var(--contrast-color) !important;
  padding: 0 !important;
}

.options__autoplay-slider .MuiSlider-rail {
  height: 4px !important;
  background: var(--contrast-color) !important;
  border-radius: 8px !important;
}

.options__autoplay-slider .MuiSlider-track {
  height: 8px !important;
  background-image: var(--contrast-color) !important;
  border-radius: 8px !important;
  border: none !important;
}

.options__autoplay-slider .MuiSlider-thumb {
  width: 20px !important;
  height: 20px !important;
  background: var(--contrast-color) !important;
  border: 2px solid #21222d !important;
}

.options__autoplay-slider .MuiSlider-thumb:hover {
  box-shadow: 0px 0px 0px 8px var(--contrast-color-opacity) !important;
}

.options__autoplay-slider .Mui-focusVisible {
  box-shadow: 0px 0px 0px 8px var(--contrast-color-opacity) !important;
}

.options__autoplay-slider .Mui-active {
  box-shadow: 0px 0px 0px 14px var(--contrast-color-opacity) !important;
}

.options__autoplay-slider .MuiSlider-thumb:hover.Mui-active {
  box-shadow: 0px 0px 0px 14px var(--contrast-color-opacity) !important;
}

.options__autoplay-slider .MuiSlider-thumb::after {
  width: 32px !important;
  height: 32px !important;
}

.options__slider-disabled {
  width: calc(100% + 8px);
  height: 20px;
  display: flex;
  position: absolute;
  top: 0;
  left: -8px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.3s ease-in !important;
}

.options__slider-disabled_visible {
  opacity: 1;
}

.options__slider-thumb {
  width: 12px;
  height: 12px;
  background: #2a2c3c;
  border: 2px solid #21222d;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 0;
  z-index: 1;
}

.options__slider-rail {
  width: 100%;
  height: 4px;
  background: var(--container-bg-color);
  border-radius: 8px;
  position: absolute;
  top: 8px;
  left: 0;
}

.options__play-btn-box {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background: var(--container-bg-color);
  backdrop-filter: blur(60px);
  box-sizing: border-box;
  padding: 6px 16px calc(24px + env(safe-area-inset-bottom));
  z-index: 2;
}

.options__play-btn {
  width: 100%;
  max-width: 860px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: var(--text-on-contrast-color);
  background: linear-gradient(92deg, #9747FF -42.66%, #D76BF0 118.44%);
  border-radius: 12px;
  border: none;
  box-sizing: border-box;
  padding: 7px 12px;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
}

.options__play-btn_network-fee {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
}

.options__play-btn_disabled {
  pointer-events: none;
  opacity: 0.3;
}

.options_disabled .options__play-btn {
  opacity: 0.3;
  pointer-events: none;
}

.options__play-btn_rolling {
  pointer-events: none;
}