.dice {
  width: 100%;
  max-width: 892px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 182px 16px calc(24px + env(safe-area-inset-bottom) + 44px + 6px);
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.dice_with-tx-queue {
  padding-top: 220px;
}

.dice__tx-in-queue {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.dice__tx-in-queue-btn {
  width: 100%;
  max-width: 256px;
  padding: 8px 12px;
  background: var(--contrast-color);
  border-radius: 12px;
  width: fit-content;
  position: absolute;
  top: 0;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  color: var(--text-color);
  border: none;
  cursor: pointer;
}

.dice__game-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  /* overflow: auto; */
}

.dice__result-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 64px;
  left: 0;
  background: var(--bg-color);
  border-bottom: 1px solid var(--border-color);
  backdrop-filter: blur(44px);
  box-sizing: border-box;
  padding: 0 0 12px;
  z-index: 100;
}

.dice__result-block_with-tx-queue {
  padding: 40px 0 12px;
}

.dice__bet-block {
  width: 100%;
  justify-content: flex-end;
  transition: opacity 0.3s ease-in;
}

.dice__bet-block_disabled {
  opacity: 0.3;
  pointer-events: none;
}

.dice__options-block {
  width: 100%;
}

.options__payout-text-block:last-of-type .options__payout-numbers {
  text-align: right;
}

.dice__options-block-preloader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
