.header {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--bg-color);
  z-index: 2;
}

.header__container {
  width: 100%;
  max-width: 892px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  box-sizing: border-box;
  padding: 0 16px;
  margin: 0 auto;
}

.header__info-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 16px;
}

.header__burger-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 8px;
  border: none;
  padding: 0;
  cursor: pointer;
  transform: scaleX(-1);
}

.header__burger-icon {
  width: 16px;
  height: 16px;
}

.header__burger-icon-fill {
  fill: var(--text-color);
}

.header__close-icon {
  width: 12px;
  height: 12px;
  opacity: 0.5;
}

.header__wallet-block {
  margin-left: auto;
  transition: opacity 0.3s ease-in;
}

.header__wallet-block_disabled {
  opacity: 0.3;
  pointer-events: none;
}

.header__demo-block {
  display: flex;
  align-items: center;
  background: var(--container-bg-color);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 4px 10px 4px 6px;
  text-decoration: none;
  color: var(--text-color);
  margin-right: auto;
  transition: opacity 0.3s ease-in;
}

.header__demo-block_disabled {
  opacity: 0.3;
  pointer-events: none;
}

.header__currency-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.header__demo-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  margin: 0;
  padding-left: 6px;
}

.header__popover {
  width: 100%;
  border: none;
  box-sizing: border-box;
  padding: 0 16px 16px;
  background: var(--bg-color);
  top: 64px;
  margin: 0;
}

.header__controls-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.header__demo-switch-container {
  display: flex;
  align-items: center;
  gap: 12px;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.header__demo-switch-container_disabled {
  opacity: 0.3;
  pointer-events: none;
}

.header__demo-switch {
  width: 43px;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 32px;
  background-color: var(--container-secondary-bg-color);
  padding: 2px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s linear;
}

.header__demo-switch-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--text-color);
  opacity: 0.8;
  transition: all 0.2s linear;
}

.header__demo-switch_active {
  background-color: var(--container-bg-color);
}

.header__demo-switch_active .header__demo-switch-circle {
  background-color: var(--contrast-color);
  transform: translateX(18px);
}

.header__demo-switch-text {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 14.63px;
  text-transform: uppercase;
}

.header__demo-switch-container_inactive {
  opacity: 0.3 !important;
}

.header__sound-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.header__sound-button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--container-bg-color);
  border-radius: 8px;
  border: none;
  padding: 0;
  cursor: pointer;
}

.header__sound-icon {
  width: 16px;
  height: 11px;
}

.header__close-app-button {
  display: flex;
  align-items: center;
  background: var(--container-bg-color);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 4px 10px 4px 6px;
  text-decoration: none;
  color: var(--text-color);
  transition: opacity 0.3s ease-in;
  border: none;
  cursor: pointer;

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 14.63px;
  text-transform: uppercase;
}