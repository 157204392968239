.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(var(--app-height) + env(safe-area-inset-bottom));
  overflow-y: hidden;
  /* По умолчанию скрываем прокрутку */

}

.Toastify__toast-container {
  max-width: 288px;
  padding: 0;
  z-index: 99 !important;
}

.Toastify__toast-container--top-right {
  top: 172px !important;
  right: 6px !important;
  left: unset !important;
}

.Toastify__toast {
  height: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  box-shadow: 0px 4px 8px rgba(9, 7, 13, 0.25);
  border-radius: 16px;
  padding: 12px;
  margin-bottom: 5px;
}

.Toastify__toast-body {
  align-items: flex-start;
  padding: 0;
}

.toast_type_copy .Toastify__toast-body {
  align-items: center;
}

.Toastify__toast-icon {
  padding: 2px;
  margin-inline-end: 8px;
}

.Toastify__progress-bar {
  height: 0;
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.6s ease-out both;
  animation: slide-in-right 0.6s ease-out both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fade-out {
  -webkit-animation: fade-out 2s ease-out both;
  animation: fade-out 2s ease-out both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    top: 175px !important;
  }

  .Toastify__toast {
    border-radius: 8px !important;
    padding: 6px !important;
  }

  .Toastify__toast-icon {
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
    padding: 1px;
  }
}