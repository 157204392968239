.dice-bet {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--container-bg-color);
  opacity: 1;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 8px 12px;
}

.dice-bet__title {
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: var(--text-color);
  opacity: 0.8;
}

.dice-bet__slider-block {
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  align-items: center;
  gap: 5px;
  margin-top: 16px;
}

.dice-bet__amount-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--container-secondary-bg-color);
  border-radius: 8px;
  border: none;
  padding: 0;
  cursor: pointer;
}

.dice-bet__amount-btn_type_increase {
  transform: rotate(-180deg);
}

.dice-bet__slider-container {
  width: 100%;
  position: relative;
  background-color: var(--container-secondary-bg-color);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 4px 8px;
  touch-action: pan-y;
}

.dice-bet__slider-line-container {
  width: 100%;
  height: 8px;
  position: relative;
  overflow: hidden;
}

.dice-bet__slider-line {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 2px);
  justify-content: start;
  gap: 2px;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: right 0.3s ease-in;
}

.dice-bet__slider-line_reverse {
  left: unset;
  justify-content: end;
}

.dice-bet__slider-line_type_active {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.dice-bet__slider-line_visible {
  opacity: 1;
}

.dice-bet__slider-item {
  width: 2px;
  height: 8px;
  background: var(--container-bg-color);
  border-radius: 2px;
}

.dice-bet__slider-item_type_active {
  background: var(--contrast-color);
}

.dice-bet__slider-container .MuiSlider-root {
  width: calc(100% - 16px) !important;
  height: 8px !important;
  position: absolute !important;
  top: 4px;
  left: 8px;
  color: transparent !important;
  padding: 0 !important;
  z-index: 10;
}

.dice-bet__slider-container .MuiSlider-thumb {
  width: 32px !important;
  height: 32px !important;
  background: var(--contrast-color) !important;
  border: 4px solid #21222d !important;
}

.dice-bet__slider-container .MuiSlider-thumb:hover {
  box-shadow: 0px 0px 0px 8px var(--contrast-color-opacity) !important;
}

.dice-bet__slider-container .Mui-focusVisible {
  box-shadow: 0px 0px 0px 8px var(--contrast-color-opacity) !important;
}

.dice-bet__slider-container .Mui-active {
  box-shadow: 0px 0px 0px 14px var(--contrast-color-opacity) !important;
}

.dice-bet__slider-container .MuiSlider-thumb:hover.Mui-active {
  box-shadow: 0px 0px 0px 14px var(--contrast-color-opacity) !important;
}

.dice-bet__slider-container .MuiSlider-thumb::after {
  width: 32px !important;
  height: 32px !important;
}

.dice-bet__slider-container .MuiSlider-valueLabel {
  font-family: 'Rubik' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 133% !important;
  letter-spacing: 0.03em !important;
  text-transform: uppercase;
  background-color: transparent !important;
  top: 0 !important;
  padding: 0 !important;
}

.dice-bet__slider-text {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.15);
  margin: 0;
  position: absolute;
  top: 20px;
  left: 0;
}

.dice-bet__slider-text_type_max {
  left: unset;
  right: 0;
}

.dice-bet__range-block {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
  background: var(--container-secondary-bg-color);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 4px;
  margin-top: 16px;
}

.dice-bet__range-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: rgba(255, 255, 255, 0.15);
  background-color: transparent;
  border: none;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
}

.dice-bet__range-button_selected {
  color: var(--contrast-color);
  background-color: var(--container-bg-color);
}
