.toast__message {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  margin: 0;
}

.toast__message_type_lost {
  color: var(--lose-color);
}

.toast__message_type_win {
  color: var(--win-color);
}

.toast__win-msg-box {
  display: flex;
  flex-direction: column;
}

.toast__message-text {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  padding-top: 4px;
}

@media only screen and (max-width: 550px) {
  .toast__message {
    font-size: 14px;
    line-height: 143%;
  }

  .toast__message_type_win {
    padding-left: 8px;
  }

  .toast__win-msg-box {
    flex-direction: row;
  }

  .toast__message-text {
    padding-top: 2px;
  }
}